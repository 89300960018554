<template>
    <Login />
</template>

<script>
import Login from '@/components/prestador/Login.vue';

export default {
    components: { Login }
};
</script>
